import styled from 'styled-components';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FC, useEffect, useState } from 'react';

import { ReactComponent as CollaborationIcon } from 'assets/icons/collaboration.svg';
import { ReactComponent as WrenchIcon } from 'assets/icons/wrench.svg';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as ItemDetailsIcon } from 'assets/icons/item-details.svg';
import { ReactComponent as StyleDetailsIcon } from 'assets/icons/styleDetailsIcon.svg';

import { ReactComponent as UnreadCollaborationIcon } from 'assets/icons/unread_collaboration.svg';
import { ReactComponent as WrenchCircleIcon } from 'assets/icons/wrench_circle.svg';
import BrowseCatalogs from 'overview/components/BrowseCatalogs';
import BrowseForms from 'overview/components/BrowseForms';
import BrowseHowTo from 'overview/components/BrowseHowTo';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { ReactComponent as BrowseCatalogsIcon } from 'assets/icons/browse-catalogs.svg';
import { ReactComponent as NoteSolidIcon } from 'assets/icons/note-solid.svg';
import { ReactComponent as LightbulbIcon } from 'assets/icons/lightbulb.svg';
import {
  OrderStatusEnums,
  OrderStylizationTypeEnums,
} from 'order/enums/orderEnums';

import { CurtainChannelTopics } from 'curtain/components/CurtainChannelTopics';

import {
  clearCurtain,
  setActiveLineItemID,
  setCreateTopic,
  setCurtainTab,
} from 'curtain/store/curtainActions';

import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';
import { ButtonIcon } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Tooltip } from 'shared/components/Tooltip';
import { lynch, silverChaliceTwo } from 'shared/config/Colors';
import { useCanEditOrder } from 'shared/hooks/useCanEditOrder';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Spacer } from 'shared/components/Layout';

import CurtainWorkablesTab from './tabs/CurtainWorkablesTab/CurtainWorkablesTab';
import { CurtainCollaborationTab } from './tabs/CurtainCollaborationTab';
import { CurtainDetailsTab } from './tabs/CurtainDetailsTab';
import { CurtainTabsEnum } from './enums/CurtainTabsEnum';
import { CurtainTopicNewSection } from './components/CurtainTopicNewSection';
import { CurtainTopicSection } from './components/CurtainTopicSection';
import { generateOrderSnapshot } from '../order/wizard/orderAcknowledgement/store/ACKActions';
import { CurtainStyleDetails } from './tabs/CurtainStyleDetails/CurtainStyleDetails';

interface CurtainWrapperProps {
  opened: boolean;
  canEditOrder: boolean;
  top?: number;
}

const CurtainWrapper = styled.div<CurtainWrapperProps>`
  background-color: ${({ theme }) => theme.white};
  border-left: 1px solid ${({ theme }) => theme.alto};
  bottom: 0;
  display: flex;
  position: fixed;
  right: 0;
  top: ${({ canEditOrder, top }) => (canEditOrder ? top ?? 159 : top ?? 115)}px;
  transform: translateX(${({ opened }) => (opened ? '0' : '421px')});
  transition: 300ms ease;
  z-index: 10;
`;

const CurtainToggles = styled.div`
  border-right: 1px solid ${({ theme }) => theme.alto};
`;

const ActionBtn = styled(ButtonIcon)`
  display: block;
  height: 64px;
  width: 64px;
  position: relative;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`;

const CurtainContainer = styled.div`
  width: 420px;
`;

interface CurtainSidebarProps {
  top?: number;
}

export const CurtainSidebar: FC<CurtainSidebarProps> = ({ top }) => {
  const { orderId } = useParams<OrderPageParams>();
  const dispatch = useDispatch();

  const canEditOrder = useCanEditOrder();

  const curtainTab = useSelector(
    (state: RootState) => state.curtainReducer.curtainTab
  );

  const createNewTopic = useSelector(
    (state: RootState) => state.curtainReducer.createTopic
  );

  const updatedTopic = useSelector(
    (state: RootState) => state.curtainReducer.updatedTopic
  );

  const activeStyleID = useSelector(
    (state: RootState) => state.curtainReducer.activeStyleID
  );
  const activeLineItemID = useSelector(
    (state: RootState) => state.curtainReducer.activeLineItemID
  );

  const activeEntityID = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityID
  );

  const activeTopic = useSelector(
    (state: RootState) => state.curtainReducer.curtainActiveTopic
  );

  const activeChannel = useSelector(
    (state: RootState) => state.curtainReducer.activeChannel
  );

  const hasWorkableReadPermission = useHasPermissions(
    userPermissionsValues.WORKABLE_READ
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isOrderSubmitted = useIsOrderInStatus({
    status: OrderStatusEnums['Not Submitted'],
    greaterThen: true,
  });

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const numberOfUnreadTopics = useSelector(
    (state: RootState) => state.curtainReducer.numberOfUnreadTopics
  );

  const numberOfUnresolvedWorkables = useSelector(
    (state: RootState) =>
      state.curtainWorkablesReducer.numberOfUnresolvedWorkables
  );

  const [snapshotModalOpened, setSnapshotModalOpened] = useState(false);

  const onActionButtonClickHandler = (tab: string) => {
    if (curtainTab === tab) {
      dispatch(clearCurtain());
    } else {
      dispatch(setCurtainTab(tab));
      dispatch(setActiveLineItemID(null));
    }
  };

  const newTopic = window.sessionStorage.getItem(
    `newtopic_${activeChannel?.id ?? '0'}`
  );

  useEffect(() => {
    if (newTopic && activeChannel) {
      dispatch(setCreateTopic(true));
    }
  }, [newTopic]);

  const CollaborationTabSection = () => {
    if (createNewTopic || updatedTopic) {
      return <CurtainTopicNewSection topic={updatedTopic} />;
    }

    if (activeTopic != null) {
      return <CurtainTopicSection />;
    }

    if (activeStyleID != null || activeLineItemID != null || activeEntityID) {
      return <CurtainChannelTopics />;
    }

    return <CurtainCollaborationTab />;
  };

  const getActiveTabColor = (tab: CurtainTabsEnum) => {
    return curtainTab === tab ? lynch : silverChaliceTwo;
  };

  const onGenerateOrderSnapshotSuccessHandler = (close: () => void) => {
    close();
    toast.success('Snapshot of the order has been taken.');
    setSnapshotModalOpened(false);
  };

  const onGenerateOrderSnapshotErrorHandler = (
    err: ServerErrorResponse,
    close: () => void
  ) => {
    UtilService.handleError({
      err,
      fallbackErrorToastMessage: 'There was an error while taking snapshot.',
    });

    close();
    setSnapshotModalOpened(false);
  };

  const onGenerateOrderSnapshot = (close: () => void) => {
    dispatch(
      generateOrderSnapshot(
        {
          orderId,
        },
        () => onGenerateOrderSnapshotSuccessHandler(close),
        (err) => onGenerateOrderSnapshotErrorHandler(err, close)
      )
    );
  };

  return (
    <CurtainWrapper
      opened={curtainTab}
      className="curtain"
      canEditOrder={canEditOrder}
      top={top}
    >
      <CurtainToggles>
        <ActionBtn
          onClick={() =>
            onActionButtonClickHandler(CurtainTabsEnum.COLLABORATION_TAB)
          }
        >
          <SVG
            icon={
              (numberOfUnreadTopics ?? 0) > 0 ? (
                <UnreadCollaborationIcon />
              ) : (
                <CollaborationIcon />
              )
            }
            color={getActiveTabColor(CurtainTabsEnum.COLLABORATION_TAB)}
            hoverColor={lynch}
          />
          {!curtainTab && <Tooltip position="left">Collaboration</Tooltip>}
        </ActionBtn>

        <ActionBtn
          onClick={() =>
            onActionButtonClickHandler(CurtainTabsEnum.DETAILS_TAB)
          }
        >
          <SVG
            icon={<ItemDetailsIcon />}
            color={getActiveTabColor(CurtainTabsEnum.DETAILS_TAB)}
            hoverColor={lynch}
          />
          {!curtainTab && <Tooltip position="left">Line Item details</Tooltip>}
        </ActionBtn>

        <ActionBtn
          onClick={() =>
            onActionButtonClickHandler(CurtainTabsEnum.STYLE_DETAILS_TAB)
          }
        >
          <SVG
            icon={<StyleDetailsIcon />}
            color={getActiveTabColor(CurtainTabsEnum.STYLE_DETAILS_TAB)}
            hoverColor={lynch}
          />
          {!curtainTab && <Tooltip position="left">Style details</Tooltip>}
        </ActionBtn>

        {hasWorkableReadPermission &&
          !isOrderSalesMaterial &&
          isOrderSubmitted && (
            <ActionBtn
              onClick={() =>
                onActionButtonClickHandler(CurtainTabsEnum.WORKABLES_TAB)
              }
            >
              <SVG
                icon={
                  (numberOfUnresolvedWorkables ?? 0) > 0 ? (
                    <WrenchCircleIcon />
                  ) : (
                    <WrenchIcon />
                  )
                }
                color={getActiveTabColor(CurtainTabsEnum.WORKABLES_TAB)}
                hoverColor={lynch}
              />
              {!curtainTab && <Tooltip position="left">Workables</Tooltip>}
            </ActionBtn>
          )}

        {isUserCSR && (
          <ActionBtn onClick={() => setSnapshotModalOpened(true)}>
            <SVG
              icon={<CameraIcon />}
              color={silverChaliceTwo}
              hoverColor={lynch}
            />

            {!curtainTab && (
              <Tooltip position="left">Take Order snapshot</Tooltip>
            )}
          </ActionBtn>
        )}
        <ActionBtn>
          <BrowseHowTo>
            <ActionBtn>
              <SVG
                color={silverChaliceTwo}
                hoverColor={lynch}
                icon={<LightbulbIcon width="30" height="30" />}
              />
              <Spacer w="10px" />
            </ActionBtn>
          </BrowseHowTo>
          <Tooltip position="left">How To</Tooltip>
        </ActionBtn>

        <ActionBtn>
          <BrowseCatalogs>
            <ActionBtn>
              <SVG
                color={silverChaliceTwo}
                hoverColor={lynch}
                icon={<BrowseCatalogsIcon width="20" height="20" />}
              />
              <Spacer w="10px" />
            </ActionBtn>
          </BrowseCatalogs>
          <Tooltip position="left">Browse Catalogs</Tooltip>
        </ActionBtn>
        <ActionBtn>
          <BrowseForms>
            <ActionBtn>
              <SVG
                color={silverChaliceTwo}
                hoverColor={lynch}
                icon={<NoteSolidIcon width="20" height="20" />}
              />
              <Spacer w="10px" />
            </ActionBtn>
          </BrowseForms>
          <Tooltip position="left">Browse Forms</Tooltip>
        </ActionBtn>

        <ConfirmationModal
          confirm={onGenerateOrderSnapshot}
          buttonText="Take a snapshot"
          cancel={() => setSnapshotModalOpened(false)}
          title="Order snapshot"
          message="The order snapshot will be stored in the attachment section"
          opened={snapshotModalOpened}
        />
      </CurtainToggles>

      <CurtainContainer>
        {curtainTab === CurtainTabsEnum.COLLABORATION_TAB &&
          CollaborationTabSection()}

        {curtainTab === CurtainTabsEnum.DETAILS_TAB && <CurtainDetailsTab />}

        {curtainTab === CurtainTabsEnum.STYLE_DETAILS_TAB && (
          <CurtainStyleDetails />
        )}

        {hasWorkableReadPermission &&
          curtainTab === CurtainTabsEnum.WORKABLES_TAB && (
            <CurtainWorkablesTab />
          )}
      </CurtainContainer>
    </CurtainWrapper>
  );
};
